<template>
  <d-view-card
    ref="view"
    :fetch-function="payoutMethodController.getPayoutMethods"
    :remove-function="payoutMethodController.removePayoutMethod"
    remove-confirm-title="Are you sure you want to delete this payout method(s)?"
    remove-success-title="Payout method(s) successfully removed"
  >
    <template #default="{ loading, find, selectedData, removeHandler, data }">
      <div class="col-grow">
        <div class="q-pa-md">
          <d-payout-methods-table
            :items="data"
            :disable-remove-btn="!selectedData.length"
            :selected.sync="$refs.view && $refs.view.$data.selectedData"
            @on-remove="removeHandler"
            :grid="$q.screen.lt.md"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DPayoutMethodsTable } from '@/features/payout-methods-table';
import DViewCard from '@/layouts/view-card';
import { payoutMethodController as api } from '@/shared/api';
import { authUtils } from '@/shared/utils';

export default {
  components: { DPayoutMethodsTable, DViewCard },
  data: () => ({
    payoutMethodController: api(authUtils.getRoleByHierarchy()),
  }),
};
</script>
