export const tableColumns = [
  {
    name: 'name',
    field: 'name',
    label: 'Name',
  },
  {
    name: 'code',
    field: 'code',
    label: 'Code',
  },
  {
    name: 'currency',
    field: (row) =>
      row?.currency ? `${row.currency.name} (${row.currency.code})` : '',
    label: 'Currency',
  },
  {
    name: 'created-at',
    field: (row) => row?.createdAt?.date,
    label: 'Created at',
  },
  {
    name: 'updated-at',
    field: (row) => row?.updatedAt?.date,
    label: 'Updated at',
  },
];
